var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.searchFlightArray, function (searchFlightItem, searchFlightIndex) {
    var _vm$getAirportByAirpo, _vm$getAirportByAirpo2;
    return _c('div', {
      key: searchFlightIndex
    }, [_c('div', {
      staticClass: "d-flex-center my-25 gap-1"
    }, [!_vm.isMobileView ? _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(_vm.$t('flight.flightSegment')) + ":")]) : _vm._e(), _c('span', {
      staticClass: "font-weight-bolder font-medium-2"
    }, [_vm._v(" " + _vm._s((_vm$getAirportByAirpo = _vm.getAirportByAirportCode(searchFlightItem.startPoint)) === null || _vm$getAirportByAirpo === void 0 ? void 0 : _vm$getAirportByAirpo.name) + " - " + _vm._s((_vm$getAirportByAirpo2 = _vm.getAirportByAirportCode(searchFlightItem.endPoint)) === null || _vm$getAirportByAirpo2 === void 0 ? void 0 : _vm$getAirportByAirpo2.name) + ", " + _vm._s(_vm.convertISODateTimeLangVN(searchFlightItem.departDate).dayWithMonth) + " ")])]), _vm._l(_vm.tripItem.journeys.filter(function (journey) {
      return journey.sequence === searchFlightIndex + 1;
    }), function (journey) {
      var _vm$selectedTrip;
      return _c('div', {
        key: journey.id,
        staticStyle: {
          "box-shadow": "4px 4px 8px -1px #18274B14"
        }
      }, [_c('AppCollapseFlight', {
        attrs: {
          "trip-id": _vm.tripItem.id,
          "item": journey,
          "is-checked": (_vm$selectedTrip = _vm.selectedTrip) === null || _vm$selectedTrip === void 0 ? void 0 : _vm$selectedTrip.journeys.some(function (j) {
            return j.sequence === searchFlightIndex + 1 && j.id === journey.id;
          })
        }
      })], 1);
    })], 2);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }