<template lang="">
  <div>
    <div
      v-for="(searchFlightItem, searchFlightIndex) of searchFlightArray"
      :key="searchFlightIndex"
    >
      <div class="d-flex-center my-25 gap-1">
        <span
          v-if="!isMobileView"
          class="font-weight-bold"
        >{{ $t('flight.flightSegment') }}:</span>
        <span class="font-weight-bolder font-medium-2">
          {{ getAirportByAirportCode(searchFlightItem.startPoint)?.name }} - {{ getAirportByAirportCode(searchFlightItem.endPoint)?.name }}, {{ convertISODateTimeLangVN(searchFlightItem.departDate).dayWithMonth }}
        </span>
      </div>
      <div
        v-for="(journey) of tripItem.journeys.filter(journey => journey.sequence === searchFlightIndex + 1)"
        :key="journey.id"
        style="box-shadow: 0px 2px 4px -2px #0000000D; box-shadow: 4px 4px 8px -1px #18274B14;"
      >
        <AppCollapseFlight
          :trip-id="tripItem.id"
          :item="journey"
          :is-checked="selectedTrip?.journeys.some(j => j.sequence === searchFlightIndex + 1 && j.id === journey.id)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { convertISODateTimeLangVN } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    AppCollapseFlight: () => import('./AppCollapseFlight.vue'),
  },
  props: {
    tripItem: {
      type: Object,
      required: true,
    },
    selectedTrip: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { getAirportByAirportCode, getSearchFlightArray: searchFlightArray } = useBookingHandle()

    searchFlightArray.value.forEach((item, index) => {
      item.journeys = props.tripItem.journeys.filter(journey => journey.sequence === index + 1)
    })

    return {
      convertISODateTimeLangVN,
      getAirportByAirportCode,
      searchFlightArray,
    }
  },
}
</script>
<style lang="">

</style>
